import { defineStore } from 'pinia'

export const usePreferenceStore = defineStore({
  id: 'preferenceStore',
  state: () => {
    return {
      showCalendar: true,
      showRightPanel: true,
      showPoolApproval: false
    }
  },
  persist: true,
})